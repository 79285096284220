import '../App.css';
import { ReactP5Wrapper } from "react-p5-wrapper";
import sketch from '../Components/Processing/sketch';


function App() {
  return (
    <div className='App'>
      <div className='app-content'>
        <h1>Drain Labs</h1>
        <div className='info'>
          <h4>Think Tank.</h4>
          <h4>We r thinking.</h4>
          <a href='mailto:tj@drainlabs.xyz' className='pitch'><strong>Have an idea?</strong></a>
        </div>
      </div>
      <ReactP5Wrapper sketch={sketch}/>
    </div>
  );
}

export default App;
