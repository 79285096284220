import {} from '../../logs'

export default function sketch(p) {
  let canvas;

  p.setup = () => {
    const c = p.createCanvas(p.windowWidth, p.windowHeight);
    c.elt.style.position = "absolute";
    c.elt.style.top = "0";
    c.elt.style.left = "0";
    p.angleMode(p.DEGREES)
    p.stroke(0, 0, 255)
    p.frameRate(15)
  }

  p.draw = () => {
    p.background(240, 240, 240)
    p.drawSegment(0, 0, p.windowHeight/2, 100)
  }

  p.drawSegment = (angle, startX, startY, length) => {
    const newX = startX + p.cos(angle)*length
    const newY = startY+ p.sin(angle)*length
    p.noFill();
    const curveX = (p.mouseX - p.windowWidth/2)*10
    const curveY = (p.mouseY - p.windowHeight/2)*10
    p.curve(startX + curveX, startY + curveY, startX, startY, newX, newY, newX - curveX, newY - curveY)

    if(p.windowWidth > newX) {
      p.drawSegment(angle + 2, newX, newY, length*2)
      p.drawSegment(angle - 2, newX, newY, length*2)
    }
  }

  p.windowResized = () => {
    p.resizeCanvas(p.windowWidth, p.windowHeight)
  }

  p.myCustomRedrawAccordingToNewPropsHandler = (newProps) => {
    if(canvas) //Make sure the canvas has been created
      p.fill(newProps.color);
  }

}
